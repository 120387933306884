const Img = {
    intro:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_img_citoryrech_default.png',
    mlIcon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_icon_Ml_default.png',
    gisIcon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_icon_GIS_default.png',
    dataIcon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_icon_DATA_default.png',
    ceIcon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_icon_company_default.png',
    dvIcon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_icon_DataVolume_default.png',
    arIcon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_icon_algorithm_default.png',
    cpIcon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_icon_partners_default.png',

}
export default Img
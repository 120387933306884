import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// const data:any ={};
// fetch("https://share.cdn.citory.tech/cdn/citorytech_web_info.json")
// .then(response=>response.json())
// .then(res=>{
//   Object.assign(data,res)
//   localStorage.setItem('data',JSON.stringify(data))
// })
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <App/>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


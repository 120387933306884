
// 模块标题下面的装饰线条
export function Line(){
    const li = Array.from('1234')
    const Li = li.map((i:string)=><li key={i}></li>)
    return (
        <ul className="blue-line">{Li}</ul>
    )
}

export function Title(props:any){
    const {en,ch} = props.value
    return (
        <div className="title-com">
            <p>{en}</p>
            <h3>{ch}</h3>
            <Line/>
        </div>
    )
}  